import React, { useState } from 'react';
import Header from '../../components/Header';
import { useEffect } from 'react';
import { buscarClientesSemPaginacao } from '../../http/Clientes';
import { buscarEspSemPaginacao } from '../../http/Esp';
import { cadastrarMaquinas } from '../../http/Maquinas';
import { useParams } from 'react-router-dom';
import { buscarMaquinaPorId } from '../../http/Maquinas';
import { editarMaquina } from '../../http/Maquinas';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EditarMaquina: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [nome, setNome] = useState('');
    const [descricao, setDescricao] = useState('');
    const [tipo, setTipo] = useState('');
    const [machineError, setMachineError] = useState('');
    const [machineSucess, setMachineSucess] = useState('');
    const [selectedClient, setSelectedClient] = useState('');
    const [clients, setClients] = useState<any[]>([]);
    const [loading, setLoading] = useState(true); // Estado de carregamento
    const [selectedEsp, setSelectedEsp] = useState('');
    const [esps, setEsp] = useState<any[]>([]);


    useEffect(() => { //buscar clientes
        const fetchClients = async () => {
            try {
                const response = await buscarClientesSemPaginacao();
                setClients(response.clients);
            } catch (error) {
                console.error('Erro ao buscar clientes:', error);
            }
        };
        fetchClients();
    }, []);

    //buscar esp
    useEffect(() => {
        const fetchEsp = async () => {
            try {
                const response = await buscarEspSemPaginacao();
                setEsp(response.sensores);
            } catch (error) {
                console.error('Erro ao buscar ESPs:', error);
            }
        };
        fetchEsp();
    }, []);

    // Busca os dados da máquina com o ID da rota
    useEffect(() => {
        const fetchMachineData = async () => {
            setLoading(true);
            try {
                const responseMachine = await buscarMaquinaPorId({ id: Number(id) });
                console.log(responseMachine);
                setNome(responseMachine.name);
                setDescricao(responseMachine.description);
                setTipo(responseMachine.type);
                setSelectedClient(responseMachine.client_id);
                setSelectedEsp(responseMachine.sensor_id);
                setLoading(false);
            } catch (error) {
                console.error('Erro ao buscar dados da máquina:', error);
            }
        };
        fetchMachineData();
    }, [id]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();


        try {
            const response = await editarMaquina({ id: Number(id), name: nome, description: descricao, client_id: selectedClient, esp_id: selectedEsp });

            if (response && response.status === 500) {
                toast.error('Erro ao vincular máquina com ESP');
            }

            if (response && response.status === 200) { // Verifica se `response` existe e se o status é 200
                toast.success('Máquina editada com sucesso');
            } else {
                toast.error('Erro ao editar registro. Tente novamente.');
            }
        } catch (error) {
            toast.error('Erro ao editar registro. Tente novamente.');
        }

    };

    return (
        <div className="flex flex-col min-h-screen bg-black text-gray-100">
            <Header />
            <main className="flex-1 p-6">
                <div className="max-w-4xl mx-auto bg-neutral-900 rounded-lg shadow-lg">
                    <div className="p-6">

                        <ToastContainer />
                        <div className="flex items-center justify-center py-4">
                            {machineSucess && (
                                <div className="bg-green-500 text-white text-lg font-semibold rounded-md shadow-lg p-4 flex items-center">
                                    <svg className="w-6 h-6 mr-2" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                                    </svg>
                                    {machineSucess}
                                </div>
                            )}

                            {machineError && (
                                <div className="bg-red-500 text-white text-lg font-semibold rounded-md shadow-lg p-4 flex items-center">
                                    <svg className="w-6 h-6 mr-2" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                    {machineError}
                                </div>
                            )}
                        </div>

                        <h2 className="text-2xl font-bold mt-6 mb-4">Editar máquina</h2>
                        <form onSubmit={handleSubmit} className="space-y-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-300">Nome da Máquina</label>
                                <input
                                    type="text"
                                    className="mt-1 block w-full bg-neutral-800 border border-neutral-700 rounded-lg p-2.5 text-gray-100"
                                    value={nome}
                                    onChange={(e) => setNome(e.target.value)}
                                    required
                                />
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-300">Descrição</label>
                                <textarea
                                    className="mt-1 block w-full bg-neutral-800 border border-neutral-700 rounded-lg p-2.5 text-gray-100"
                                    value={descricao}
                                    onChange={(e) => setDescricao(e.target.value)}
                                    required
                                />
                            </div>
                            {/* 
              <div>
                <label className="block text-sm font-medium text-gray-300">Tipo</label>
                <input
                  type="text"
                  className="mt-1 block w-full bg-neutral-800 border border-neutral-700 rounded-lg p-2.5 text-gray-100"
                  value={tipo}
                  onChange={(e) => setTipo(e.target.value)}
                  required
                />
              </div> */}

                            {/* vincular com cliente */}
                            <div>
                                <label htmlFor="state">Vincular a cliente:</label>
                                <select className="mt-1 block w-full bg-neutral-800 border border-neutral-700 rounded-lg p-2.5 text-gray-100"
                                    id="state"
                                    value={selectedClient}
                                    onChange={(e) => setSelectedClient(e.target.value)}
                                >
                                    <option value="">Selecione um cliente</option>
                                    {clients.map((client) => (
                                        <option key={client.id} value={client.id}>
                                            {client.name} {/* Use o nome do estado conforme o banco de dados */}
                                        </option>
                                    ))}
                                </select>
                            </div>



                            {/* vincular com ESP */}
                            <div>
                                <label htmlFor="state">Vincular a ESP:</label>
                                <select className="mt-1 block w-full bg-neutral-800 border border-neutral-700 rounded-lg p-2.5 text-gray-100"
                                    id="state"
                                    value={selectedEsp}
                                    onChange={(e) => setSelectedEsp(e.target.value)}
                                >
                                    <option value="">Selecione um ESP</option>
                                    {esps.map((esp) => (
                                        <option key={esp.id} value={esp.id}>
                                            {esp.mac} {/* Use o nome do estado conforme o banco de dados */}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <button
                                type="submit"
                                className="w-full bg-custom-blue text-white rounded-lg p-2.5 mt-4 font-medium"
                            >
                                Editar
                            </button>
                        </form>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default EditarMaquina;