import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../../components/Header';
import { Link } from 'react-router-dom';
import { buscarClientes } from '../../http/Clientes';
import { desativarCliente } from '../../http/Clientes';
import { ativarCliente } from '../../http/Clientes';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Clientes: React.FC = () => {
  const [clients, setClients] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [clientError, setClientError] = useState('');
  const [clientSucess, setClientSucess] = useState('');

  // Função para buscar clientes do backend
  const fetchClients = async (page: number) => {
    try {
      const response = await buscarClientes(page);
      setClients(response.clients);
      setTotalPages(Math.ceil(response.total / 5)); // Atualiza total de páginas com base no total de clientes
    } catch (error) {
      console.error('Erro ao buscar clientes:', error);
    }
  };

  // Função para desativar cliente
  const handleDelete = async (id: number) => {
    try {
      await desativarCliente({ id });
      fetchClients(currentPage);
      toast.success('Cliente desativado com sucesso');
    } catch (error) {
      toast.error('Erro ao desativar cliente. Tente novamente.');
    }
  };

  // Função para ativar cliente
  const handleActivate = async (id: number) => {
    try {
      await ativarCliente({ id });
      fetchClients(currentPage);
      toast.success('Cliente ativado com sucesso');
    } catch (error) {
      toast.error('Erro ao ativar cliente. Tente novamente.');
    }
  };

  // Função para editar cliente
  const handleEdit = (id: number) => {
    // Redireciona para a página de edição
    window.location.href = `/editar_cliente/${id}`;
  };


  useEffect(() => {
    fetchClients(currentPage);
  }, [currentPage]);

  return (
    <div className="flex flex-col min-h-screen bg-black text-gray-100">
      <Header />
      <main className="flex-1 p-6">
        
        <ToastContainer />
        <div className="flex items-center justify-center py-4">
          {clientSucess && (
            <div className="bg-green-500 text-white text-lg font-semibold rounded-md shadow-lg p-4 flex items-center">
              <svg className="w-6 h-6 mr-2" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
              </svg>
              {clientSucess}
            </div>
          )}

          {clientError && (
              <div className="bg-red-500 text-white text-lg font-semibold rounded-md shadow-lg p-4 flex items-center">
                <svg className="w-6 h-6 mr-2" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
                {clientError}
              </div>
            )}
      </div>



        <div className="max-w-full mx-auto bg-neutral-900 rounded-lg shadow-lg">
          <div className="p-6 flex items-center justify-between">

            <h1 className="text-2xl font-bold mb-4">Clientes cadastrados</h1>
            <div className="flex gap-x-4">
              <Link
                to="/cadastro_cliente"
                className="text-sm md:text-base lg:text-lg bg-custom-blue text-white hover:bg-blue-700 hover:text-gray-200 py-2 px-4 rounded-lg"
              >
                + Cliente
              </Link>
            </div>
          </div>

          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-neutral-700">
              <thead>
                <tr>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-300">Nome</th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-300">CNPJ</th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-300">Endereço</th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-300">Cidade</th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-300">Estado</th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-300">Status</th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-300">Ações</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-neutral-700">
                {clients.length > 0 ? (
                  clients.map((client) => (
                    <tr key={client.id}>
                      <td className="px-4 py-2 text-sm text-gray-100">{client.name}</td>
                      <td className="px-4 py-2 text-sm text-gray-100">{client.cnpj}</td>
                      <td className="px-4 py-2 text-sm text-gray-100">{client.street}</td>
                      <td className="px-4 py-2 text-sm text-gray-100">{client.city}</td>
                      <td className="px-4 py-2 text-sm text-gray-100">{client.state}</td>
                      <td className="px-4 py-2 text-sm text-gray-100">{client.deleted_at ? 'Inativo' : 'Ativo'}</td>
                      <td className="px-4 py-2 text-sm text-gray-100">
                      <button className="bg-blue-500 text-white px-3 py-1 rounded-lg hover:bg-blue-600" onClick={() => handleEdit(client.id)}>Editar</button>
                          {client.deleted_at ? (
                              <button className="bg-green-500 text-white px-3 py-1 rounded-lg hover:bg-green-600 ml-2" onClick={() => handleActivate(client.id)}>Ativar</button>
                          ) : (
                              <button className="bg-red-500 text-white px-3 py-1 rounded-lg hover:bg-red-600 ml-2" onClick={() => handleDelete(client.id)}>Desativar</button>
                          )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={6} className="px-4 py-2 text-sm text-gray-100 text-center">Nenhum cliente encontrado</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className="flex justify-between mt-4">
            <button
              onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              className="bg-gray-600 text-white px-4 py-2 rounded"
            >
              Anterior
            </button>
            <button
              onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
              disabled={currentPage >= totalPages}
              className="bg-gray-600 text-white px-4 py-2 rounded"
            >
              Próximo
            </button>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Clientes;