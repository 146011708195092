import React, { useState } from 'react';
import Header from '../../components/Header';
import axios from 'axios';
import { useEffect } from 'react';
import { formataCnpj, formataCelular, formataCep } from '../../utils/Formatters';
import { buscarEstados, cadastrarCliente } from '../../http/Clientes';
import { buscarCidades } from '../../http/Clientes';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
interface State {
  id: number;
  name: string; // Nome do estado conforme o banco de dados
  uf: string; // Unidade federativa (abreviação do estado)
}

interface City {
  id: number;
  name: string; // Nome da cidade
  state_id: number; // ID do estado ao qual a cidade pertence
}

const CadastrarCliente: React.FC = () => {
  
  const [name, setName] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [street, setStreet] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [number, setNumber] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [email, setEmail] = useState('');
  const [cellphone, setCellphone] = useState(''); 
  const [clientSucess, setClientSucess] = useState('');
  const [clientError, setClientError] = useState('');
  const [states, setStates] = useState<State[]>([]);
  const [cities, setCities] = useState<City[]>([]);
  const [selectedState, setSelectedState] = useState<string>('');
  const [selectedCity, setSelectedCity] = useState<string>('');

  useEffect(() => {
    // Buscar estados e cidades quando o componente for montado
    const fetchStates = async () => {
      try {
        const response = await buscarEstados(); // Espera a função buscarEstados
        setStates(response || []); // Atualiza o estado com a resposta ou um array vazio
      } catch (error) {
        console.error('Erro ao buscar estados:', error);
      }
    };

    const fetchCities = async () => {
      try {
        const response = await buscarCidades(); // Espera a função buscarEstados
        setCities(response || []);
      } catch (error) {
        console.error('Erro ao buscar cidades:', error);
      }
    };

    fetchStates();
    fetchCities();
  }, []);
    
  // Função para formatar o CNPJ
  const handleCNPJChange = (e: { target: { value: string; }; }) => {
    const formattedCNPJ = formataCnpj(e.target.value);
    setCnpj(formattedCNPJ);
  };


  // Função para formatar o celular
  const handleCellphoneChange = (e: { target: { value: string; }; }) => {
    const formattedCellphone = formataCelular(e.target.value);
    setCellphone(formattedCellphone);
  };


  // Função para formatar o CEP
  const handlePostalCodeChange = (e: { target: { value: string; }; }) => {
    const formattedPostalCode = formataCep(e.target.value);
    setPostalCode(formattedPostalCode);
  };

  
  // Função para submeter o formulário
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

  // Validação do CNPJ
  const cnpjRegex = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/;
  if (!cnpjRegex.test(cnpj)) {
    toast.error('Formato de CNPJ inválido');
    return;
  } else {
    setClientError('');
  }

  // Validação do celular
  const cellphoneRegex = /^\(\d{2}\) \d{5}-\d{4}$/;
  if (!cellphoneRegex.test(cellphone)) {
    toast.error('Formato de celular inválido');
    return;
  }

  // Validação do CEP
  const postalCodeRegex = /^\d{5}-\d{3}$/;
  if (!postalCodeRegex.test(postalCode)) {
    toast.error('Formato de CEP inválido'); 
    return;
  }


    // Lógica de submissão do formulário

    try {
      await cadastrarCliente( {
        name,
        cnpj,
        address: {
          street,
          postal_code: postalCode,
          number,
          neighborhood,
          id_city: selectedCity,
          id_state: selectedState
        },
        contacts: {
          email,
          cellphone
        }
      });
      toast.success('Cliente cadastrado com sucesso');
    } catch (error) {
      toast.error('Erro ao cadastrar cliente. Tente novamente.');
    }
    

  };

  return (
    <div className="flex flex-col min-h-screen bg-black text-gray-100">
      <Header />
      <main className="flex-1 p-6">
        <div className="max-w-4xl mx-auto bg-neutral-900 rounded-lg shadow-lg">
          <div className="p-6">

            <ToastContainer />
            <div className="flex items-center justify-center py-4">
              {clientSucess && (
                <div className="bg-green-500 text-white text-lg font-semibold rounded-md shadow-lg p-4 flex items-center">
                  <svg className="w-6 h-6 mr-2" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                  </svg>
                  {clientSucess}
                </div>
              )}

              {clientError && (
                  <div className="bg-red-500 text-white text-lg font-semibold rounded-md shadow-lg p-4 flex items-center">
                    <svg className="w-6 h-6 mr-2" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    {clientError}
                  </div>
                )}
            </div>




            <h2 className="text-2xl font-bold mt-6 mb-4">Cadastrar novo cliente</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-300">Nome</label>
                <input
                  type="text"
                  className="mt-1 block w-full bg-neutral-800 border border-neutral-700 rounded-lg p-2.5 text-gray-100"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300">CNPJ</label>
                <input
                  type="text"
                  className="mt-1 block w-full bg-neutral-800 border border-neutral-700 rounded-lg p-2.5 text-gray-100"
                  value={cnpj}
                  // acionar a função de formatação do CNPJ
                  //a inserção do valor ocorre no onChange (setCNPJ)
                  onChange={(e) => handleCNPJChange(e)}
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300">Rua</label>
                <input
                  type="text"
                  className="mt-1 block w-full bg-neutral-800 border border-neutral-700 rounded-lg p-2.5 text-gray-100"
                  value={street}
                  onChange={(e) => setStreet(e.target.value)}
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300">CEP</label>
                <input
                  type="text"
                  className="mt-1 block w-full bg-neutral-800 border border-neutral-700 rounded-lg p-2.5 text-gray-100"
                  value={postalCode}
                  onChange={(e) => handlePostalCodeChange(e)}
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300">Número</label>
                <input
                  type="text"
                  className="mt-1 block w-full bg-neutral-800 border border-neutral-700 rounded-lg p-2.5 text-gray-100"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300">Bairro</label>
                <input
                  type="text"
                  className="mt-1 block w-full bg-neutral-800 border border-neutral-700 rounded-lg p-2.5 text-gray-100"
                  value={neighborhood}
                  onChange={(e) => setNeighborhood(e.target.value)}
                  required
                />
              </div>

              <div>
                <label htmlFor="state">Estado:</label>
                <select className="mt-1 block w-full bg-neutral-800 border border-neutral-700 rounded-lg p-2.5 text-gray-100"
                  id="state"
                  value={selectedState}
                  onChange={(e) => setSelectedState(e.target.value)}
                >
                  <option value="">Selecione um estado</option>
                  {states.map((state) => (
                    <option key={state.id} value={state.id}>
                      {state.name} {/* Use o nome do estado conforme o banco de dados */}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label htmlFor="city">Cidade:</label>
                <select className="mt-1 block w-full bg-neutral-800 border border-neutral-700 rounded-lg p-2.5 text-gray-100"
                  id="city"
                  value={selectedCity}
                  onChange={(e) => setSelectedCity(e.target.value)}
                >
                  <option value="">Selecione uma cidade</option>
                  {cities
                    .filter((city) => city.state_id === parseInt(selectedState))
                    .map((city) => (
                      <option key={city.id} value={city.id}>
                        {city.name} {/* Use o nome da cidade conforme o banco de dados */}
                      </option>
                    ))}
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300">Email</label>
                <input
                  type="email"
                  className="mt-1 block w-full bg-neutral-800 border border-neutral-700 rounded-lg p-2.5 text-gray-100"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300">Celular</label>
                <input
                  type="text"
                  className="mt-1 block w-full bg-neutral-800 border border-neutral-700 rounded-lg p-2.5 text-gray-100"
                  value={cellphone}
                  onChange={(e) => handleCellphoneChange(e)}
                  required
                />
              </div>


              <div className="flex items-center">
                <button
                  type="submit"
                  className="w-full bg-custom-blue text-white rounded-lg p-2.5 mt-4 font-medium"
                >
                  Cadastrar
                </button>
                {/* botão de voltar */}
                <a href="/clientes" className="w-full bg-gray-600 text-white rounded-lg p-2.5 mt-4 font-medium text-center">Voltar</a>
              </div>
            </form>
          </div>
        </div>
      </main>
    </div>
  );
};

export default CadastrarCliente;