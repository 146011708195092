import React, { useState, useEffect } from 'react';
import Header from '../../components/Header';
import axios from 'axios';
import { buscarClientesSemPaginacao } from '../../http/Clientes';
import { cadastrarEsp } from '../../http/Esp';
import { formataMac } from '../../utils/Formatters';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Cadastrar_esp: React.FC = () => {
  const [macAddress, setMacAddress] = useState('');
  const [macError, setMacError] = useState('');
  const [macSucess, setMacSucess] = useState('');
  const [clients, setClients] = useState<any[]>([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [notificacaoStatus, setNotificacaoStatus] = useState(''); // Para o status da notificação
  const [telegramGroupId, setTelegramGroupId] = useState(''); // Para o ID do grupo do Telegram

  // Função para formatar o MAC ADDRESS
  const handleMacAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedMacAdress = formataMac(e.target.value);
    setMacAddress(formattedMacAdress);
  };

  useEffect(() => { // buscar clientes
    const fetchClients = async () => {
      try {
        const response = await buscarClientesSemPaginacao();
        setClients(response.clients);
      } catch (error) {
        console.error('Erro ao buscar clientes:', error);
      }
    };
    fetchClients();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const macRegex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
    if (!macRegex.test(macAddress)) {
      toast.error('Formato de MAC address inválido');
      return;
    } else {
      setMacError('');
    }

    try {
      const response = await cadastrarEsp({
        mac: macAddress,
        client_id: selectedClient,
        notificacaoStatus,
        telegramGroupId,
      });

      if (response.status === 200) {
        toast.success('Registro bem-sucedido');
      } else {
        toast.error('Erro ao fazer registro. Tente novamente.');
      }
    } catch (error) {
      toast.error('Erro ao fazer registro. Tente novamente.');
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-black text-gray-100">
      <Header />
      <main className="flex-1 p-6">

        <div className="max-w-4xl mx-auto bg-neutral-900 rounded-lg shadow-lg">
          <div className="p-6">
            <ToastContainer />
            <div className="flex items-center justify-center py-4">
              {macSucess && (
                <div className="bg-green-500 text-white text-lg font-semibold rounded-md shadow-lg p-4 flex items-center">
                  <svg className="w-6 h-6 mr-2" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                  </svg>
                  {macSucess}
                </div>
              )}

              {macError && (
                <div className="bg-red-500 text-white text-lg font-semibold rounded-md shadow-lg p-4 flex items-center">
                  <svg className="w-6 h-6 mr-2" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                  {macError}
                </div>
              )}
            </div>

            <h2 className="text-2xl font-bold mt-6 mb-4">Cadastrar novo ESP32</h2>
            <form onSubmit={handleSubmit} className="space-y-4">

              <div>
                <label className="block text-sm font-medium text-gray-300">MAC Address (ESP32)</label>
                <input
                  type="text"
                  className={`mt-1 block w-full bg-neutral-800 border ${macError ? 'border-red-600' : 'border-neutral-700'} rounded-lg p-2.5 text-gray-100`}
                  value={macAddress}
                  onChange={handleMacAddressChange}
                  placeholder="00:00:00:00:00:00"
                  required
                />
              </div>

              {/* Vincular com cliente */}
              <div>
                <label htmlFor="client">Vincular a cliente:</label>
                <select
                  className="mt-1 block w-full bg-neutral-800 border border-neutral-700 rounded-lg p-2.5 text-gray-100"
                  id="client"
                  value={selectedClient}
                  onChange={(e) => setSelectedClient(e.target.value)}
                >
                  <option value="">Selecione um cliente</option>
                  {clients.map((client) => (
                    <option key={client.id} value={client.id}>
                      {client.name} {/* Use o nome do cliente conforme o banco de dados */}
                    </option>
                  ))}
                </select>
              </div>

    

              {/* Campo para ID do grupo do Telegram */}
              <div>
                <label className="block text-sm font-medium text-gray-300">ID do Grupo do Telegram</label>
                <input
                  type="text"
                  className="mt-1 block w-full bg-neutral-800 border border-neutral-700 rounded-lg p-2.5 text-gray-100"
                  value={telegramGroupId}
                  onChange={(e) => setTelegramGroupId(e.target.value)}
                  placeholder="Digite o ID do grupo"
                  required
                />
              </div>

              {/* Radio buttons para status de notificação */}
              <div>
                <label className="block text-sm font-medium text-gray-300">Status de Notificação</label>
                <div className="flex items-center mt-2">
                  <label className="mr-4">
                    <input
                      type="radio"
                      value="ativo"
                      checked={notificacaoStatus === 'ativo'}
                      onChange={(e) => setNotificacaoStatus(e.target.value)}
                      className="mr-2"
                    />
                    Ativo
                  </label>
                  <label>
                    <input
                      type="radio"
                      value="inativo"
                      checked={notificacaoStatus === 'inativo'}
                      onChange={(e) => setNotificacaoStatus(e.target.value)}
                      className="mr-2"
                    />
                    Inativo
                  </label>
                </div>
              </div>
              <div className="flex items-center">
                <button
                  type="submit"
                  className="w-full bg-custom-blue text-white rounded-lg p-2.5 mt-4 font-medium"
                >
                  Cadastrar
                </button>
                <a href="/esp32" className="w-full bg-gray-600 text-white rounded-lg p-2.5 mt-4 font-medium text-center">Voltar</a>
              </div>
            </form>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Cadastrar_esp;