import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { jwtDecode } from 'jwt-decode';

interface AuthContextType {
  isAuthenticated: boolean;
  isLoading: boolean; // Novo estado de carregamento
  role: string | null; // Adicionar o nível de permissão do usuário
  client_id: string | null; // Adicionar o ID do cliente
  login: (token: string) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [role, setRole] = useState<string | null>(null); // Novo estado para `role`
  const [client_id, setClient_id] = useState<string | null>(null); // Novo estado para `client_id`
  const [isLoading, setIsLoading] = useState<boolean>(true); // Novo estado de carregamento

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      try {
        // Verifica se o token ainda é válido
        const decoded: any = jwtDecode(token); // Usa a função correta
        const currentTime = Date.now() / 1000; // Tempo atual em segundos

        if (decoded.exp && decoded.exp > currentTime) {
          setIsAuthenticated(true);
          setRole(decoded.role || null); // Define o estado `role`
          setClient_id(decoded.client_id || null); // Define o estado `client_id`
        } else {
          setIsAuthenticated(false);
          localStorage.removeItem('token');
        }
      } catch (error) {
        setIsAuthenticated(false);
        localStorage.removeItem('token');
      }
    } else {
      setIsAuthenticated(false);
    }
    setIsLoading(false); // Finaliza o carregamento
  }, [isAuthenticated]);


  

  const login = (token: string) => {
    try {
      const decoded: any = jwtDecode(token);
      localStorage.setItem('token', token);
      setIsAuthenticated(true);
      setRole(decoded.role || null); // Atualizar role
      setClient_id(decoded.client_id || null); // Atualizar client_id
    } catch (error) {
      console.error("Erro ao decodificar o token", error);
      setIsAuthenticated(false); // Defina como false em caso de erro
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    setRole(null);
    setClient_id(null);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, isLoading, role, client_id, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth deve ser usado dentro de um AuthProvider');
  }
  return context;
};
