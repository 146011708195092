import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../../components/Header';
import { Link } from 'react-router-dom';
import { buscarMaquinas, buscarMaquinasCliente } from '../../http/Maquinas';
import { desativarMaquina, ativarMaquina } from '../../http/Maquinas';
import { useAuth } from '../../auth/AuthContext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Maquinas: React.FC = () => {
  const [machines, setMachines] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [machineError, setMachineError] = useState('');
  const [machineSucess, setMachineSucess] = useState('');
  const {  role, client_id } = useAuth();

  // Função para buscar máquinas do backend
  const fetchMachines = async (page: number) => {
    try {

      if(role === 'admin') {
      // Busca máquinas de acordo com o perfil do usuário
      const response = await buscarMaquinas(page);
      setMachines(response.machines);
      setTotalPages(Math.ceil(response.total / 5)); // Atualiza total de páginas com base no total de máquinas
      } else {
      const response = await buscarMaquinasCliente(client_id, page);
      setMachines(response.machines);
      setTotalPages(Math.ceil(response.total / 5)); // Atualiza total de páginas com base no total de máquinas
      }

    } catch (error) {
      console.error('Erro ao buscar máquinas:', error);
    }
  };

  // Função para desativar máquina
  const handleDelete = async (id: number) => {
    try {
      await desativarMaquina({ id });
      fetchMachines(currentPage);
      toast.success('Máquina desativada com sucesso');
    } catch (error) {
      console.error('Erro ao desativar máquina:', error);
      toast.error('Erro ao desativar máquina. Tente novamente.');
    }
  };

  // Função para editar máquina
  const handleEdit = (id: number) => {
    // Redireciona para a página de edição
    window.location.href = `/editar_maquina/${id}`;
  };

  // Função para ativar máquina
  const handleActivate = async (id: number) => {
    try {
      await ativarMaquina({ id });
      fetchMachines(currentPage);
      toast.success('Máquina ativada com sucesso');
    } catch (error) {
      console.error('Erro ao ativar máquina:', error);
      toast.error('Erro ao ativar máquina. Tente novamente.');
    }
  }

  useEffect(() => {
    fetchMachines(currentPage);
  }, [currentPage]);

  return (
    <div className="flex flex-col min-h-screen bg-black text-gray-100">
      <Header />
      <main className="flex-1 p-6">

        <ToastContainer />
        <div className="flex items-center justify-center py-4">
              {machineSucess && (
                  <div className="bg-green-500 text-white text-lg font-semibold rounded-md shadow-lg p-4 flex items-center">
                      <svg className="w-6 h-6 mr-2" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                      </svg>
                      {machineSucess}
                  </div>
              )}

              {machineError && (
                  <div className="bg-red-500 text-white text-lg font-semibold rounded-md shadow-lg p-4 flex items-center">
                      <svg className="w-6 h-6 mr-2" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                      {machineError}
                  </div>
              )}
        </div>




        <div className="max-w-full mx-auto bg-neutral-900 rounded-lg shadow-lg">
          <div className="p-6 flex items-center justify-between">
            <h1 className="text-2xl font-bold mb-4">Máquinas Cadastradas</h1>
            <div className="flex gap-x-4">
              <Link
                to="/cadastro_maquina"
                className="text-sm md:text-base lg:text-lg bg-custom-blue text-white hover:bg-blue-700 hover:text-gray-200 py-2 px-4 rounded-lg"
              >
                + Máquina
              </Link>


            </div>

          </div>

          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-neutral-700">
              <thead>
                <tr>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-300">Nome</th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-300">Descrição</th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-300">Cliente</th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-300">ESP (mac)</th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-300">Ações</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-neutral-700">
                {machines.length > 0 ? (
                  machines.map((machine) => (
                    <tr key={machine.id}>
                      <td className="px-4 py-2 text-sm text-gray-100">{machine.name}</td>
                      <td className="px-4 py-2 text-sm text-gray-100">{machine.description}</td>
                      <td className="px-4 py-2 text-sm text-gray-100">{machine.client_name}</td>
                      <td className="px-4 py-2 text-sm text-gray-100">{machine.mac}</td>
                      <td className="px-4 py-2 text-sm text-gray-100">
                      <button className="bg-blue-500 text-white px-3 py-1 rounded-lg hover:bg-blue-600" onClick={() => handleEdit(machine.id)}>Editar</button>
                      {machine.deleted_at ? (
                          <button className="bg-green-500 text-white px-3 py-1 rounded-lg hover:bg-green-600 ml-2" onClick={() => handleActivate(machine.id)}>Ativar</button>
                        ) : (
                          <button className="bg-red-500 text-white px-3 py-1 rounded-lg hover:bg-red-600 ml-2" onClick={() => handleDelete(machine.id)} >Desativar</button>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={5} className="px-4 py-2 text-sm text-gray-100 text-center">Nenhuma máquina encontrada</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className="flex justify-between mt-4">
            <button
              onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              className="bg-gray-600 text-white px-4 py-2 rounded"
            >
              Anterior
            </button>
            <button
              onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
              disabled={currentPage >= totalPages}
              className="bg-gray-600 text-white px-4 py-2 rounded"
            >
              Próximo
            </button>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Maquinas;