import axios from "axios";

interface maquinaRequest {
    name: string;
    description: string;
    client_id: string;
    esp_id: string;
}

interface editarMaquinaRequest {
    id: number;
    name: string;
    description: string;
    client_id: string;
    esp_id: string;
}

interface maquinaRequestId {
    id: number;
}

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export async function buscarMaquinas(page: number) {
    try {
        const response = await axios.get(`${API_BASE_URL}/machines?page=${page}&limit=5`);
        if(response.status === 200){
            return response.data;
        } else {
            throw new Error('Erro ao buscar máquinas');
        }
    } catch (error) {
        console.error('Erro ao buscar máquinas:', error);
    }
}

export async function buscarMaquinasCliente(id: string | null, page: number) {
    try {
        const response = await axios.get(`${API_BASE_URL}/machines-client/${id}?page=${page}&limit=5`);
        if(response.status === 200){
            return response.data;
        } else {
            throw new Error('Erro ao buscar máquinas do cliente');
        }
    } catch (error) {
        console.error('Erro ao buscar máquinas do cliente:', error);
    }
}

export async function desativarMaquina({ id }: { id: number }) {
    try {
        const response = await axios.post(`${API_BASE_URL}/machines-delete/${id}`);
        if(response.status === 200){
            return response;
        } else {
            throw new Error('Erro ao desativar máquina');
        }
    } catch (error) {
        console.error('Erro ao desativar máquina:', error);
    }
}

export async function ativarMaquina({ id }: { id: number }) {
    try {
        const response = await axios.post(`${API_BASE_URL}/machines-activate/${id}`);
        if(response.status === 200){
            return response;
        } else {
            throw new Error('Erro ao ativar máquina');
        }
    } catch (error) {
        console.error('Erro ao ativar máquina:', error);
    }
}


export async function cadastrarMaquinas({ name, description, client_id, esp_id }: maquinaRequest) {
    try {
        const response = await axios.post(`${API_BASE_URL}/machines-register`, { 
            name, 
            description, 
            client_id, 
            esp_id 
        });

        if (response.status === 500) { // Verifica se `response` existe e se o status é 500
            return response; // Retorna o objeto completo da resposta
        } else if (response.status === 200) {
            return response; // Retorna o objeto completo da resposta
        } else {
            throw new Error('Erro ao cadastrar máquina');
        }
    } catch (error) {
        console.error('Erro ao cadastrar máquina:', error);
        return undefined; // Garante que sempre retorne algo, mesmo em caso de erro
    }
}


export async function buscarMaquinaPorId({ id }: maquinaRequestId) {
    try {
        const response = await axios.get(`${API_BASE_URL}/machine-id/${id}`);
        if(response.status === 200){
            return response.data;
        } else {
            throw new Error('Erro ao buscar máquina por ID');
        }
    } catch (error) {
        console.error('Erro ao buscar máquina por ID:', error);
    }
}

export async function editarMaquina({id, name, description, client_id, esp_id }: editarMaquinaRequest) {
    try {
        const response = await axios.post(`${API_BASE_URL}/machine-update/${id}`, { 
            name, 
            description, 
            client_id, 
            esp_id 
        });
        if(response.status === 200){
            return response;
        } else {
            throw new Error('Erro ao editar máquina');
        }
    } catch (error) {
        console.error('Erro ao editar máquina:', error);
    }
}